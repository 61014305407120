<template>
  <div v-if="loaded" class="navbar-setting">
    <NavSetting
      :navConfig="navConfig"
      :trendConfig="trendConfig"
      :activitys="activitys"
      :pages="pages"
      @refresh="refresh"
    />
    <SearchRecommand
      :activitys="activitys"
      :categorys="categorys"
      :navConfig="navConfig"
      :trendConfig="trendConfig"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import NavSetting from './components/NavSetting.vue'
import SearchRecommand from './components/SearchRecommand.vue'
import { GetNavConfig } from '@/api/ecommerce/navSetting'
import { GetPageSetting } from '@/api/ecommerce/pageSetting'
import { GetProductCategory, GetProductCategoryCount } from '@/api/ecommerce/category'
import { getAllDataFromApi } from '@/utils/helper'
import { GetActivity, GetActivityCount } from '@/api/ecommerce/activity'
import { mapGetters } from 'vuex'
import { remove, filter, find } from 'lodash'
import dayjs from 'dayjs'

export default {
  name: 'NavbarSetting',
  components: { SearchRecommand, NavSetting },
  data: () => ({
    searchRecommand: [],
    navConfig: [],
    trendConfig: [],
    activitys: [],
    categorys: [],
    pages: [],
    loaded: false,
  }),
  computed: {
    ...mapGetters(['shop']),

  },

  async mounted () {
    this.loaded = false
    await this.refresh()
    this.loaded = true
  },
  methods: {
    async refresh () {
      await this.getActivity()
      await this.getCategory()
      await this.getPageSetting()
      await this.getNavConfig()
    },
    clearNotExist (configData) {
      const avaliables = configData.filter(config => {
        if (config.type === 'ecommercePage') {
          const exist = find(this.pages, { id: config.ecommercePageId })
          if (exist) return config
        }
        if (config.type === 'ecommerceCategory') {
          const exist = find(this.categorys, { id: config.ecommerceCategoryId })
          if (exist) return config
        }
        if (config.type === 'ecommerceActivity') {
          const exist = find(this.activitys, { id: config.ecommerceActivityId })
          if (exist) return config
        }
        if (config.type === 'link') return config
      })
      return avaliables
    },

    async getNavConfig () {
      const [res, err] = await GetNavConfig({ shopId: this.shop })
      if (err) return
      this.navConfig = res.navigation
      this.navConfig = this.clearNotExist(res.navigation)
      this.trendConfig = this.clearNotExist(res.trend)

      remove(this.navConfig, i => i.type === 'allProduct')
    },

    async getActivity () {
      const [max, err] = await GetActivityCount({ shopId: this.shop })
      if (err) return this.$message.error(err)
      const config = {
        start: 0,
        limit: 100,
        shopId: this.shop,
      }
      const [res, error] = await getAllDataFromApi(max.count, GetActivity, config, true)
      if (error) return this.$message.error(error)
      this.activitys = res
      this.activitys = filter(this.activitys, i => !dayjs().isAfter(dayjs(i.endAt)) && !i.isStorewideActivity)
    },
    async getCategory () {
      const [max, err] = await GetProductCategoryCount({ shopId: this.shop })
      if (err) return this.$message.error(err)
      const config = {
        start: 0,
        limit: 100,
        shopId: this.shop,
      }
      const [res, error] = await getAllDataFromApi(max.count, GetProductCategory, config, true)
      if (error) return this.$message.error(error)
      this.categorys = res
    },
    async getPageSetting () {
      const [res, err] = await GetPageSetting({ shopId: this.shop })
      if (err) return this.$message.error(err)
      this.pages = res
    },
  },
}
</script>

<style lang="postcss" scoped>
.navbar-setting {
  @apply grid gap-[30px];
}
</style>
