import { admin2 } from '@/api/instance'

export const GetNavConfig = async ({
  shopId,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceNavConfig`,
  })
  return res
}

export const UpdateNavConfig = async ({
  shopId,
  navigation,
  trend,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceNavConfig`,
    data: {
      navigation,
      trend,
    },
  })
  return res
}
