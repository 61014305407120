<template>
  <el-dialog
    title="上層欄功能設定"
    width="670px"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <template slot="title">
      <p class="page-title">上層欄功能設定</p>
      <p class="hint">*請至少選擇一項功能</p>
    </template>

    <el-form v-if="Object.keys(formData).length" ref="form" :model="formData" :rules="formRules" label-position="top">
      <el-form-item label="排序1">
        <span style="margin-left: 15px">所有類別</span>
      </el-form-item>

      <el-form-item v-for="(config, key, index) in formData" :key="key" :label="`排序${index + 2}`">
        <NavOptions
          :objKey="key"
          :activitys="activitys"
          :pages="pages"
          :navConfigData="formData[key]"
          @update="updateNavData($event, key)"
        />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button plain @click="cancel">取消</el-button>
      <el-button type="primary" @click="updateNavConfig">儲存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import NavOptions from './NavOptions.vue'
import { UpdateNavConfig } from '@/api/ecommerce/navSetting'
import { clone, forEach, remove } from 'lodash'
import { mapGetters } from 'vuex'
import { noEmptyRules, urlRules } from '@/validation'
import formUtils from '@/utils/form'

export default {
  name: 'NavSettingDialog',
  components: { NavOptions },
  props: ['trendConfig', 'navConfig', 'activitys', 'pages'],
  computed: {
    ...mapGetters(['shop']),
    compactData () {
      const list = [
        { type: 'allProduct' },
      ]
      forEach(this.formData, item => {
        if (item) list.push(item)
      })
      remove(list, i => !i.type)
      return list
    },
  },
  data: () => ({
    config: null,
    formData: {},
    formRules: {},
  }),
  async mounted () {
    this.generateFormRules()
    this.syncConfigFormData()
  },
  methods: {
    cancel () {
      this.$emit('close')
    },
    confirm () {
      this.$emit('close')
    },
    async updateNavConfig () {
      if (!await formUtils.checkForm(this.$refs.form)) return
      const [, err] = await UpdateNavConfig({
        shopId: this.shop,
        navigation: this.compactData,
        trend: this.trendConfig,
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新成功！')
      this.$emit('refresh')
      this.$emit('close')
    },
    updateNavData (e, key) {
      this.formData[key] = e
    },
    generateFormRules () {
      for (let c = 0; c < 6; c++) {
        this.$set(this.formRules, `nav${c}.name`, [noEmptyRules()])
        this.$set(this.formRules, `nav${c}.url`, [noEmptyRules(), urlRules()])
        this.$set(this.formRules, `nav${c}.ecommerceActivityId`, [noEmptyRules()])
        this.$set(this.formRules, `nav${c}.ecommercePageId`, [noEmptyRules()])
      }
    },
    syncConfigFormData () {
      const config = clone(this.navConfig)
      for (let c = 0; c < 5; c++) {
        this.$set(this.formData, `nav${c}`, config[c] || {})
      }
    },

  },
}
</script>

<style lang="postcss" scoped>
.page-title {
  @apply text-[24px] font-medium;
}

.hint {
  @apply text-sub text-gray-80 font-medium;
}

::v-deep .el-dialog__body {
  @apply py-0;
}
::v-deep .el-select {
  @apply !w-full;
}
::v-deep .el-input {
  @apply !w-full;
}
</style>
