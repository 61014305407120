<template>
  <div class="nav-setting card-container">
    <SectionTitle title="上層欄功能設定" @edit="openDialog = true" />
    <el-form v-if="Object.keys(formData).length">
      <el-form-item
        v-for="(config, key, index) in formData"
        :key="config.id"
        :label="`排序${index+1}`"
        style="margin-top: 24px"
      >
        <div class="inner-form-item">
          <p v-if="!config.type" style="color: #AEAEAE !important">尚未選擇</p>
          <p v-if="config.type === 'link' || config.type === 'allProduct'">{{ navType(config.type) }}</p>
          <p v-if="config.type === 'link'">{{ config.name }}</p>
          <p v-if="config.type === 'link'">{{ config.url }}</p>
          <p v-if="config.type === 'ecommerceActivity'">{{ findActivity(config.ecommerceActivityId) }}</p>
          <p v-if="config.type === 'ecommercePage'">{{ findPage(config.ecommercePageId) }}</p>
        </div>
      </el-form-item>
    </el-form>
    <NavSettingDialog
      v-if="openDialog && navConfig"
      :navConfig="navConfig"
      :trendConfig="trendConfig"
      :activitys="activitys"
      :pages="pages"
      @close="openDialog = false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import NavSettingDialog from './NavSettingDialog.vue'
import { navSettingTypes } from '@/config/ecommerce'
import { clone, find } from 'lodash'

export default {
  name: 'NavSetting',
  components: { SectionTitle, NavSettingDialog },
  props: ['navConfig', 'trendConfig', 'activitys', 'pages'],
  data: () => ({
    openDialog: false,
    formData: {},
  }),
  watch: {
    navConfig () {
      this.syncConfigFormData()
    },
  },

  mounted () {
    this.syncConfigFormData()
  },
  methods: {
    syncConfigFormData () {
      const config = clone(this.navConfig)
      config.unshift({ type: 'allProduct' })
      for (let c = 0; c < 6; c++) {
        this.$set(this.formData, `nav${c}`, config[c] || {})
      }
    },

    findActivity (actID) {
      if (!actID) return ''
      const exist = find(this.activitys, act => act.id === actID)
      if (exist) return exist.name
      return ''
    },
    findPage (pageID) {
      if (!pageID) return ''
      const exist = find(this.pages, page => page.id === pageID)
      if (exist) return exist.title
      return ''
    },

    navType (type) {
      const data = navSettingTypes[type]
      if (data) return data.name
      return ''
    },
  },
}
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__content {
  @apply font-normal;
}

.nav-setting {
  @apply px-[33px] py-[24px];
}

.inner-form-item {
  @apply ml-[100px];
}
</style>
